.main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background: rgb(89,195,255);
}
header{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    width: auto;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 15px;
}
main,
.greeting{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: auto;
    height: auto;
    margin: 0 auto;
}
main{
    width: 95%;
    max-width: 1240px;
}
main>div,
.greeting{
    margin-bottom: 50px;
}
.greeting{
    margin-top: 30px;
}
.greeting h3,
.greeting h4{
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    color: #fff;
    font-size: 25px;
    text-align: center;
}
.greeting h4{
    font-size: 20px;
}
@media (min-width: 768px){
    main{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .greeting{
        width: 100%;
    }
    main>div{
        margin-bottom: 0;
    }
}
@media (min-width: 992px){
    main{
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin-top: auto;
        margin-bottom: auto;
    }
    .greeting{
        margin-top: 0;
    }
}