.modal{
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    z-index: 1000;
}

.modal__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 90%;
    max-width: 500px;
    min-height: 100px;
    padding: 10px;
    top: 30%;
    background: #f9f9f9;
    border-radius: 10px;
    -webkit-transform: translateY(-70%);
    -ms-transform: translateY(-70%);
    transform: translateY(-70%);
}
.tempClose{
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    margin: 10px 0 0;
}
.showModal{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}