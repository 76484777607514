.type{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
}
.type h4{
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    font-size: 20px;
}
@media (min-width: 768px) {
    .type {
        width: 45%;
        max-width: 329px;
        height: 145px;
    }
}